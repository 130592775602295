import {useEffect, useMemo} from 'react';
import {InstalmentPlan as InstalmentPlanEntity} from 'lib/graphql/API';
import {PopOverSidebar} from '../../../components/organisms/PopOverSidebar';
import {
  AbsoluteDate,
  fieldSorter,
  formatMoney,
  toTitleCase,
} from 'payble-shared';
import {UseDisclosureReturn} from '../../../lib/hooks/useDisclosure';
import {FormHeader} from '../forms/components/FormHeader';
import {Alert, Switch} from 'payble-ui';
import {FormSubmission} from '../forms/components/FormSubmission';
import {Spinner} from '../../../components/atoms/Spinner';
import {Formik} from 'formik';
import {capitalize} from 'payble-shared';
import classNames from 'classnames';
import {useAPIMutation} from '../../../lib/api';
import {PlanResponseType} from 'payble-api-client/schemas/plan';

interface InstalmentPlanRebalanceDrawerProps {
  disclosure: UseDisclosureReturn;
  plan: InstalmentPlanEntity;
}

export const InstalmentPlanRebalanceDrawer = ({
  disclosure,
  plan,
}: InstalmentPlanRebalanceDrawerProps) => {
  return (
    <PopOverSidebar {...disclosure}>
      <InstalmentPlanRebalanceForm disclosure={disclosure} plan={plan} />
    </PopOverSidebar>
  );
};

function InstalmentPlanRebalanceForm({
  disclosure,
  plan,
}: InstalmentPlanRebalanceDrawerProps) {
  const rebalance = useAPIMutation('rebalancePlan');

  const {outcome} = plan.rebalance;
  const cause = plan.rebalance.cause
    ? capitalize(plan.rebalance.cause)
    : 'Unknown issue';

  const cannotRebalance = outcome === 'notPossible';

  const canSubmit = !rebalance.isPending && !cannotRebalance;

  useEffect(() => {
    if (outcome === 'notPossible') {
      return;
    }

    rebalance.mutate({
      dryRun: true,
      instalmentPlanId: plan.id,
      disableNotifications: true,
    });
  }, []);

  const changes = useMemo(() => {
    if (!rebalance.data) return;

    const {instalments} = rebalance.data;

    const changes = compareInstalments(plan.instalments, instalments);
    return changes;
  }, [rebalance.data, plan.instalments]);

  return (
    <Formik
      initialValues={{disableNotifications: true}}
      onSubmit={async f => {
        await rebalance.mutateAsync({
          dryRun: false,
          instalmentPlanId: plan.id,
          disableNotifications: f.disableNotifications,
        });
        disclosure.onClose();
      }}
    >
      {f => (
        <form
          className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
          onSubmit={f.handleSubmit}
        >
          <FormHeader
            setOpen={disclosure.onClose}
            title="Sync Payment Schedule"
            description="Manually synchronize this payment schedule with the accounts balance"
          />

          {outcome === 'possibleWithWarning' && (
            <Alert
              variant="warning"
              title="Synchronization is possible with warning"
              className="rounded-none"
            >
              {cause ?? 'Unknown issue'}
            </Alert>
          )}

          {outcome === 'notPossible' && (
            <Alert variant="error" title="Synchronization is not possible">
              {cause ?? 'Unknown issue'}
            </Alert>
          )}

          {rebalance.isPending && (
            <div className="flex items-center justify-center h-32">
              <Spinner />
            </div>
          )}

          <div className="p-4">
            {!!changes && !!rebalance.data && (
              <InstalmentComparisonTable
                initial={plan}
                rebalanced={rebalance.data}
                instalments={changes}
              />
            )}
          </div>

          <div className="flex-grow" />
          {rebalance.error && (
            <Alert title="Couldn't sync payment schedule" variant="error">
              {rebalance.error.message}
            </Alert>
          )}
          {canSubmit && (
            <div className="flex-shrink-0 px-4 py-5 sm:px-6 sticky bottom-0 bg-white">
              <div className="text-sm font-semibold">Disable Notifications</div>
              <Switch
                id="disableNotifications"
                checked={f.values.disableNotifications}
                onCheckedChange={() =>
                  f.setFieldValue(
                    'disableNotifications',
                    !f.values.disableNotifications
                  )
                }
              />
            </div>
          )}
          {canSubmit && (
            <FormSubmission
              isSubmitting={rebalance.isPending}
              onCancel={disclosure.onClose}
              submissionButtonText="Sync"
              cancelButtonText="Cancel"
              submissionDisabled={rebalance.isPending}
            />
          )}
        </form>
      )}
    </Formik>
  );
}

/**
 * This display was wholesale ripped from <InstalmentTable />
 */
function InstalmentComparisonTable(props: {
  instalments: ComparedInstalment[];
  initial: InstalmentPlanEntity;
  rebalanced: PlanResponseType;
}) {
  const {instalments, initial, rebalanced} = props;

  const rows = instalments.map(i => {
    return (
      <div key={i.instalmentId} className="flex py-2 text-xs sm:text-sm">
        <div className="px-2 basis-4/12">
          {i.dueAt?.toFormat('dd MMM yyyy')}
        </div>
        <div className="px-2 text-right basis-4/12">
          {formatMoney(i.amount)}
        </div>
        <div className="justify-center px-2 pl-6 text-center basis-4/12">
          <div
            className={classNames(
              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs sm:text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
              i.change === 'decreased' || i.change === 'removed'
                ? 'bg-green-100 text-green-800'
                : '',
              i.change === 'increased' || i.change === 'added'
                ? 'bg-red-100 text-red-800'
                : '',
              i.change === 'no change' ? 'bg-gray-100 text-gray-800' : ''
            )}
          >
            {toTitleCase(i.change)} {'delta' in i && formatMoney(i.delta)}
          </div>
        </div>
      </div>
    );
  });

  const overallResult =
    rebalanced.amountDue === initial.amountDue
      ? 'no change'
      : rebalanced.amountDue > initial.amountDue
        ? 'increased'
        : 'decreased';
  return (
    <div className="mt-4 divide-y divide-gray-50">
      <div className="flex py-2 text-xs font-semibold sm:text-sm">
        <div className="px-2 basis-4/12">Due Date</div>
        <div className="px-2 text-right basis-4/12">Amount</div>
        <div className="px-2 pl-6 text-center basis-4/12">Change</div>
      </div>
      {rows}

      <div className="mt-4 divide-y divide-gray-50">
        <div className="flex py-2 text-xs font-semibold sm:text-sm">
          <div className="px-2 basis-4/12"></div>
          <div className="px-2 text-right basis-4/12">New Total</div>
          <div className="px-2 pl-6 text-center basis-4/12">Change</div>
        </div>
      </div>

      <div className="flex py-2 text-xs sm:text-sm">
        <div className="px-2 basis-4/12"></div>
        <div className="px-2 text-right basis-4/12">
          {formatMoney(rebalanced.amountDue)}
        </div>
        <div className="justify-center px-2 pl-6 text-center basis-4/12">
          <div
            className={classNames(
              'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs sm:text-sm font-medium md:mt-2 lg:mt-0 place-self-center',
              overallResult === 'decreased'
                ? 'bg-green-100 text-green-800'
                : '',
              overallResult === 'increased' ? 'bg-red-100 text-red-800' : '',
              overallResult === 'no change' ? 'bg-gray-100 text-gray-800' : ''
            )}
          >
            {toTitleCase(overallResult)}{' '}
            {overallResult !== 'no change' &&
              formatMoney(rebalanced.amountDue - initial.amountDue)}
          </div>
        </div>
      </div>
    </div>
  );
}

type BaseInstalment = {
  instalmentId: string;
  amount: number;
  dueAt: AbsoluteDate | null;
};

type ComparedInstalment =
  | (BaseInstalment & {change: 'increased'; delta: number})
  | (BaseInstalment & {change: 'decreased'; delta: number})
  | (BaseInstalment & {change: 'no change'})
  | (BaseInstalment & {change: 'removed'})
  | (BaseInstalment & {change: 'added'});

/**
 * This logic is based on the assumption that an instalment does not change its
 * due date without updating its amount. If this assumption is incorrect, this
 * function will need to be updated.
 */
function compareInstalments(
  initial: InstalmentPlanEntity['instalments'],
  updated: PlanResponseType['instalments']
) {
  const initialScheduled = initial.filter(
    i => i.status === 'scheduled' || i.status === 'overdue'
  );

  const updatedScheduled = updated.filter(
    i => i.status === 'scheduled' || i.status === 'overdue'
  );

  const deleted = initialScheduled
    .filter(i => !updatedScheduled.find(u => u.instalmentId === i.instalmentId))
    .map<ComparedInstalment>(i => ({
      instalmentId: i.instalmentId,
      dueAt: i.dueAt,
      amount: i.amount,
      change: 'removed',
    }));

  const updates = updatedScheduled.flatMap<ComparedInstalment>(updated => {
    const initial = initialScheduled.find(
      i => i.instalmentId === updated.instalmentId
    );

    if (!initial) {
      return [
        {
          change: 'added',
          instalmentId: updated.instalmentId,
          amount: updated.amount,
          dueAt: updated.dueAt,
        },
      ];
    }

    if (initial.amount === updated.amount) {
      return [
        {
          change: 'no change',
          instalmentId: updated.instalmentId,
          amount: updated.amount,
          dueAt: updated.dueAt,
        },
      ];
    }

    return [
      {
        instalmentId: updated.instalmentId,
        change: initial.amount < updated.amount ? 'increased' : 'decreased',
        amount: updated.amount,
        delta: updated.amount - initial.amount,
        dueAt: updated.dueAt,
      },
    ];
  });

  return [...deleted, ...updates].sort(
    fieldSorter(i => i.dueAt, {
      dir: 'ASC',
    })
  );
}
