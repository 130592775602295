import React from 'react';
import {FormikErrors} from 'formik';
import {Select} from 'components/atoms/Select';
import {AbsoluteDate, formatToCents, formatToDollars} from 'payble-shared';
import CurrencyInput from 'react-currency-input-field';
import {AddInstalmentPlanFormValues} from '../AddInstalmentPlanForm';
import {FormErrorMessage} from 'features/audience/components/FormErrorMessage';
import {Mode, SelectMode} from './SelectMode';
import {InstalmentFrequency} from 'lib/graphql/API';

const modes: Mode[] = [
  {
    name: 'End Date',
    id: 'instalmentAmount',
    description: 'Setup plan to end on a specific date',
  },
  {
    name: 'Total Amount',
    id: 'totalAmount',
    description: 'Setup plan to end when the total amount is reached',
  },
];

type SetupInstalmentsProps = {
  values: AddInstalmentPlanFormValues;
  errors: FormikErrors<AddInstalmentPlanFormValues>;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  localStartOfDay: AbsoluteDate;
  defaultValues?: {
    mode?: string;
    InstalmentAmount?: number;
    planTotalAmount?: number;
    planFrequency?: InstalmentFrequency;
    startDate?: AbsoluteDate;
  };
};

export const SetupInstalments: React.FC<SetupInstalmentsProps> = ({
  values,
  errors,
  setFieldValue,
  localStartOfDay,
  defaultValues,
}) => {
  const mode = modes.find(mode => {
    if (values.mode) {
      return mode.id === values.mode;
    }

    if (defaultValues?.mode) {
      return mode.id === defaultValues.mode;
    }
  });

  const ensureValidDateRange = () => {
    if (values.startDate && values.endDate) {
      if (values.startDate.isAfter(values.endDate)) {
        setFieldValue('endDate', values.startDate.plus({days: 1}));
        return;
      }
    }

    if (values.startDate) {
      if (values.startDate.isBefore(localStartOfDay)) {
        setFieldValue('startDate', values.startDate);
        return;
      }
    }
  };

  return (
    <div className="space-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0">
      <div className="sm:col-span-2">
        <SelectMode
          selectedMode={mode}
          modes={modes}
          setMode={mode => {
            setFieldValue('mode', mode.id);
          }}
        />
      </div>
      {mode && (
        <>
          <div className="sm:col-span-1">
            <label
              htmlFor="startDate"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Start Date
            </label>
            <div className="mt-2">
              <input
                id="startDate"
                name="startDate"
                type="date"
                min={localStartOfDay.toISO()}
                onChange={e => {
                  const date = AbsoluteDate.maybeFromISO(e.target.value);
                  if (date) setFieldValue('startDate', date);
                }}
                onBlur={ensureValidDateRange}
                value={values.startDate?.toISO()}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-1">
            {mode.id === 'instalmentAmount' ? (
              <>
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  End date
                </label>
                <div className="mt-2">
                  <input
                    id="endDate"
                    name="endDate"
                    type="date"
                    defaultValue={localStartOfDay.toISO()}
                    value={values.endDate?.toISO() ?? localStartOfDay.toISO()}
                    min={values.startDate?.toISO() ?? localStartOfDay.toISO()}
                    onChange={e => {
                      const date = AbsoluteDate.maybeFromISO(
                        e.currentTarget.value
                      );
                      if (date) setFieldValue('endDate', date);
                    }}
                    onBlur={ensureValidDateRange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6  disabled:cursor-not-allowed"
                  />
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="amountOwing"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Plan Value
                </label>
                <div className="mt-2">
                  <CurrencyInput
                    name="amountOwing"
                    id="amountOwing"
                    className="block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Amount"
                    prefix="$"
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    defaultValue={
                      defaultValues?.planTotalAmount
                        ? formatToDollars(defaultValues?.planTotalAmount)
                        : undefined
                    }
                    onValueChange={value =>
                      setFieldValue(
                        'planTotalAmount',
                        value ? formatToCents(parseFloat(value)) : ''
                      )
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="col-span-2">
            {errors.startDate && (
              <FormErrorMessage message={errors.startDate} />
            )}
          </div>
          <div className="sm:col-span-1">
            <label
              htmlFor="frequency"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Frequency
            </label>
            <div className="mt-2">
              <Select
                defaultValue={defaultValues?.planFrequency}
                values={[
                  {
                    id: 'weekly',
                    name: 'Weekly',
                  },
                  {
                    id: 'fortnightly',
                    name: 'Fortnightly',
                  },
                  {
                    id: 'monthly',
                    name: 'Monthly',
                  },
                  {
                    id: 'quarterly',
                    name: 'Quarterly',
                  },
                ]}
                onChange={async value => {
                  setFieldValue('frequency', value);
                }}
              />
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="instalmentAmount"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Instalment Amount
            </label>
            <div className="mt-2">
              <CurrencyInput
                name="amount"
                id="amount"
                className="block w-full border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Amount"
                prefix="$"
                allowNegativeValue={false}
                defaultValue={
                  defaultValues?.InstalmentAmount
                    ? formatToDollars(defaultValues?.InstalmentAmount)
                    : undefined
                }
                decimalsLimit={2}
                onValueChange={value =>
                  setFieldValue(
                    'instalmentAmount',
                    value ? formatToCents(Number(value)) : ''
                  )
                }
              />
            </div>
            <div className="col-span-2">
              {errors.instalmentAmount && (
                <FormErrorMessage message={errors.instalmentAmount} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
