import {Fragment, useEffect} from 'react';
import {Transition} from '@headlessui/react';
import {
  XCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';
import {AbsoluteDate, WEB_CONTEXT} from 'payble-shared';

export const MaintenanceBanner = () => {
  const {isOpen, onClose, onOpen} = useDisclosure();

  useEffect(() => {
    const today = AbsoluteDate.today(WEB_CONTEXT);
    if (today.isAfterOrEqual(AbsoluteDate.fromISO('2024-12-25'))) return;

    const hideBanner = window.localStorage.getItem('hideBanner');
    if (!hideBanner) {
      onOpen();
    }
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div className="flex justify-between w-full p-4 shadow-md bg-yellow-50 border border-yellow-100 text-yellow-700">
          <div className="flex items-center mx-auto">
            <p className="flex items-center text-sm md:text-md font-medium">
              <span className="inline-flex p-1 me-3 items-center justify-center flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-yellow-700"
                  aria-hidden="true"
                />
              </span>
              <span className="text-center">
                Payble will have planned maintenance outages on 23rd December
                2024 from 10pm to 2am and 30th December 2024 from 10pm to 2am.{' '}
                <br />
                During these times, the system may not be available.
              </span>
            </p>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => {
                window.localStorage.setItem('hideBanner', 'true');
                onClose();
              }}
              type="button"
              className="inline-flex p-1 me-3 flex-shrink-0"
            >
              <XCircleIcon
                className="h-5 w-5 text-yellow-700 hover:text-yellow-600"
                aria-hidden="true"
              />
              <span className="sr-only">Close banner</span>
            </button>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};
