import * as React from 'react';
import {FormConfig, FormConfigField} from 'payble-shared';
import {Form, FormProps} from './Form';
import {FormBuilderField} from './FormBuilderField';

export interface FormBuilderProps {
  id: string;
  schema: NonNullable<FormProps['schema']>;
  defaultValues?: FormProps['defaultValues'];
  onSubmit: FormProps['onSubmit'];
  fields: FormConfigField[];
  children?: React.ReactNode;
  context?: FormConfig['context'];
  layout?: {
    columns?: 1 | 2 | 3;
  };
  size?: 'sm' | 'default';
}

export const FormBuilder = ({
  id,
  schema,
  fields,
  defaultValues,
  onSubmit,
  children,
  context,
  layout,
  size,
}: FormBuilderProps) => {
  const cols = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
  }[layout?.columns ?? 1];

  return (
    <Form
      id={id}
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={onSubmit}
    >
      <div className={`grid gap-4 ${cols}`}>
        {fields.map(field => (
          <FormBuilderField
            key={`${id}-${field.name}`}
            field={{size, ...field}}
            context={context}
          />
        ))}
      </div>
      {children}
    </Form>
  );
};
