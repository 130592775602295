import {z} from 'zod';
import {planResponse} from '../schemas';
import {defineHTTPEndpoint} from '../utils';

export const rebalancePlan = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'POST /rebalance-plan',
  requestSchema: z.object({
    instalmentPlanId: z.string(),
    dryRun: z.boolean(),
    disableNotifications: z.boolean(),
  }),
  responseSchema: planResponse,
});
