import {FieldArray, FormikErrors} from 'formik';
import React from 'react';
import {SetupInstalments} from './SetupInstalments';
import {AddInstalmentPlanFormValues} from '../AddInstalmentPlanForm';
import {EditInstalment} from './EditInstalment';
import {ViewInstalment} from './ViewInstalment';
import {PlanSummary} from './PlanSummary';
import {InstalmentFrequency} from 'lib/graphql/API';
import {AbsoluteDate} from 'payble-shared';

type InstalmentsProp = {
  values: AddInstalmentPlanFormValues;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  localStartOfDay: AbsoluteDate;
  errors: FormikErrors<AddInstalmentPlanFormValues>;
  defaultValues?: {
    mode?: string;
    InstalmentAmount?: number;
    planTotalAmount?: number;
    planFrequency?: InstalmentFrequency;
    startDate?: AbsoluteDate;
  };
};

export const Instalments: React.FC<InstalmentsProp> = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  localStartOfDay,
  defaultValues,
}) => {
  const [editRowIndex, setEditRowIndex] = React.useState<number | undefined>(
    undefined
  );

  return (
    <FieldArray name="instalments">
      {({remove}) => (
        <div>
          <div className="flex-shrink-0 px-4 py-5 border-t border-gray-200 sm:px-6">
            {values.instalments.length === 0 ? (
              <SetupInstalments
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                localStartOfDay={localStartOfDay}
                defaultValues={defaultValues}
              />
            ) : (
              <PlanSummary
                startDate={values.startDate}
                endDate={values.endDate}
                instalments={values.instalments}
              />
            )}
          </div>
          {values.instalments?.length > 0 ? (
            <div className="px-5 py-2 divide-y divide-gray-200">
              <div className="flex items-center px-5 py-2 text-xs font-semibold sm:text-sm">
                <div className="px-2 basis-4/12">Due at</div>
                <div className="px-2 text-left basis-2/12">Amount</div>
                <div className="justify-center px-2 pl-6 text-right basis-6/12">
                  Actions
                </div>
              </div>
              {values.instalments.map(({dueAt, amount, __id}, index) => (
                <div key={`instalment-${__id}`}>
                  {editRowIndex === index ? (
                    <EditInstalment
                      index={index}
                      setInstalment={() => {
                        setEditRowIndex(undefined);
                      }}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      values={values}
                      localStartOfDay={localStartOfDay}
                    />
                  ) : (
                    <ViewInstalment
                      dueAt={dueAt}
                      amount={amount}
                      edit={() => setEditRowIndex(index)}
                      remove={() => remove(index)}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </FieldArray>
  );
};
