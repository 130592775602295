export const sortObjectByKey = <T extends Record<string, unknown>>(
  obj: T
): T => {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj: Record<string, unknown> = {};

  sortedKeys.forEach(key => {
    sortedObj[key] = obj[key];
  });

  return sortedObj as T;
};

export const isDeepEmpty = function (obj: any): boolean {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.keys(obj).every(key => isDeepEmpty(obj[key]));
  }
  return obj === null || obj === undefined || obj === '' || obj?.length === 0;
};

export const getDotNotationValue = (obj: any, dotNotation: string) => {
  return dotNotation.split('.').reduce((acc, key) => acc?.[key], obj);
};

type NonEmpty<T> = T extends object
  ? {[K in keyof T]: NonEmpty<T[K]>} extends infer R
    ? {[K in keyof R]: R[K]}
    : never
  : T;

export const deepClearEmpty = <T>(obj: T): NonEmpty<T> => {
  if (Array.isArray(obj)) {
    return obj
      .map(item => deepClearEmpty(item))
      .filter(
        item => item !== null && item !== undefined && item !== ''
      ) as NonEmpty<T>;
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, deepClearEmpty(value)])
        .filter(
          ([__, value]) =>
            value !== null &&
            value !== undefined &&
            value !== '' &&
            (typeof value !== 'object' || Object.keys(value).length)
        )
    ) as NonEmpty<T>;
  }
  return obj as NonEmpty<T>; // Return non-object types as is
};
